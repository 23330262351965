@media only screen and (min-width: 1380px) {}

@media only screen and (min-width: 1501px) {}

@media only screen and (min-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1380px) {}

@media only screen and (max-width: 1199px) {}


@media only screen and (max-width: 1024px) {
    html {
        font-size: 42%;
    }

    .header .navbar .navbar-toggler {
        border-color: #fff !important;
    }

    .header .navbar .navbar-toggler .navbar-toggler-icon {
        background-image: url(../../../public/images/menu.png) !important;
    }

    .header.fix-top .navbar .navbar-toggler .navbar-toggler-icon {
        background-image: url(../../../public/images/menu1.png) !important;
    }

    .header .navbar .navbar-collapse {
        padding-top: 20px;
    }

    .header.fix-top .navbar .navbar-toggler {
        border-color: #000 !important;
    }

    .header .navbar .nav-item.apply-btn a {
        display: inline-block;
    }

    .header.fix-top .logo2 {
        margin-top: -4px;
    }

    .banner .carousel-inner .carousel-item .carousel-caption .bg-text {
        padding-right: 0;
    }

    .count .count-box {
        min-height: 164px;
        min-width: 164px;
    }

    .count .c-c-box .count-box .icon-box {
        bottom: -52px;
    }

    .count .c-c-box .count-box .icon-box img {
        width: 80px;
    }

    .testimonial .testimonial-boxes .testimonial-box .t-icon i {
        font-size: 4rem;
    }

    .learn-cook .cook-box .cook-img img {
        width: 60px;
    }

}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
    html {
        font-size: 48%;
    }

    .header .navbar .logo img {
        width: 70px;
    }

    .header.fix-top .logo2 img {
        width: 70px;
    }

    .header.bg-wite .navbar .nav-item.apply-btn a {
        color: #fff;
        border-bottom: none;
    }

    .header.bg-wite {
        background-color: #fff;
        -webkit-box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .16);
        box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%);
    }

    .header.bg-wite .logo {
        display: none;
    }

    .header.bg-wite .logo2 {
        display: inline-block;
        margin-top: -11px;
    }

    .header.bg-wite .logo2 img {
        width: 70px;
    }

    .header.bg-wite .navbar .nav-item .nav-link {
        color: #000;
        font-weight: 600;
    }

    .header.bg-wite .navbar .navbar-toggler .navbar-toggler-icon {
        background-image: url(../../../public/images/menu1.png) !important;
    }


    .banner .carousel-inner .carousel-item h2 {
        font-size: 3rem;
    }

    .banner .carousel-inner .carousel-item .carousel-caption {
        bottom: 20%;
        left: 0%;
        width: 100%;
    }

    .banner .carousel-inner .carousel-item h3 {
        font-size: 1.8rem;
    }

    .banner .carousel-inner .carousel-item p {
        font-size: 1.6rem;
        width: 36rem;
    }

    .banner .carousel-inner .carousel-item .apply-btn {
        margin-top: 1.8rem;
    }

    .banner .carousel-inner .carousel-item .apply-btn a {
        font-size: 10px;
        padding: 6px 15px;
    }

    .banner .carousel-inner .carousel-item .carousel-caption .bg-text {
        width: 100%;
        padding-right: 0;
    }

    .banner .carousel-inner .carousel-item .carousel-caption .b-img {
        display: none;
    }

    .most-popular-courses h1 {
        margin-bottom: 1.8rem;
    }

    .most-popular-courses .nav-tabs {
        display: none;
    }

    .most-popular-courses .tab-content {
        display: none;
    }

    .most-popular-courses .nav-tabs .nav-item a {
        font-size: 1.5rem;
    }

    .most-popular-box .t-text a {
        font-size: 1rem;
        padding: 10px 14px;
    }

    .most-popular-courses .most-popular-video-mob {
        display: block;
        padding-top: 14px;
    }

    .most-popular-courses .most-popular-video-mob video {
        width: 100%;
    }


    .learn-cook .c-b:nth-child(1) {
        padding: 0 20px;
    }

    .learn-cook .c-b:nth-child(2) {
        padding: 0 20px;
    }

    .learn-cook .cook-box {
        margin-bottom: 20px;
    }

    .learn-cook .order1 {
        order: 1;
    }

    .learn-cook .order2 {
        order: 2;
    }

    .learn-cook .learn-right-text h5 {
        transform: none;
        font-size: 40px;
        padding: 0 20px 30px;
    }

    .learn-cook .cook-box .cook-img img {
        width: 70px;
    }

    .count {
        padding: 5rem 0;
    }

    .count .count-box {
        min-height: 150px;
        min-width: 0;
        width: 150px;
        margin: 0 auto;
    }

    .count .c-c-box {
        margin-bottom: 5rem;
    }

    .count .c-c-box .count-box .icon-box {
        bottom: -30px;
    }

    .count .c-c-box .count-box .icon-box img {
        width: 60px;
    }

    footer .upper-footer .footer-address {
        padding-left: 20px;
        margin-bottom: 20px;
    }

    footer .upper-footer .footer-link {
        margin: 20px 0;
    }

    footer .upper-footer .footer-left {
        text-align: left;
    }

    footer .upper-footer .logo2 {
        margin-bottom: 10rem;
    }

    footer .upper-footer .footer-left .study {
        padding-left: 0;
    }

    footer .upper-footer .footer-address {
        padding-left: 0px;
    }

    footer .upper-footer h3 {
        padding-bottom: 3rem;
    }

    .about-us .about-image img:nth-child(2) {
        display: none;
    }

    .g-imgs .modal .modal-dialog {
        max-width: 300px;
        margin: 0 auto;
        margin-top: 70%;
    }

    .about-img {
        order: 2;
    }

    .about-text {
        order: 1;
    }

    .thim-widget-icon-box {
        margin-bottom: 3rem;
    }

    .block-section .card {
        margin-bottom: 3rem;
    }

}

@media only screen and (max-width: 567px) {}